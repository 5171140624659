<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <avatar
          :username="getMerchantUserName()"
          :size="36"
          color="white"
          background-color="#725CF6"
        ></avatar>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="goTo('/user/profile')">
      <CIcon name="cil-user" /> User Profile
    </CDropdownItem>
    <CDropdownItem @click="goToSettings()" v-element-access="permissions.settingsView">
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Avatar from "vue-avatar";
import * as types from '@/store/mutation-types';

export default {
  name: "TheHeaderDropdownAccnt",
  components: {
    Avatar,
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store.commit(types.setToken, null);
      this.$store.commit(types.setIsMerchantVerified, false);
      this.$store.commit(types.toggleSandboxMode, false);
      this.$store.commit(types.setUserRole, null);
      this.$store.commit(types.setMerchantID, null);
      this.$store.commit(types.setEmailID, null);
      this.$store.commit(types.setDefaultPermissionState);
      this.$router.push("/login");
    },
    goToSettings() {
      this.goTo("/settings");
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>