<template>
    <CHeader fixed with-subheader light>
      <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
      <!--<CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')"/>-->
      <CSubheader in-header class="float-left col-6 ml-2">
        <CBreadcrumbRouter class="border-0 mb-0" />
      </CSubheader>
      <CHeaderNav class="d-md-down-none mr-auto"></CHeaderNav>
      <CHeaderNav class="mr-4">
        <CDropdown
            :togglerText="getLocale()"
            nav
            placement="bottom-end"
        >
          <CDropdownItem @click="changeLanguage('en')">EN</CDropdownItem>
          <CDropdownItem @click="changeLanguage('id')">ID</CDropdownItem>
        </CDropdown>

        <TheHeaderDropdownAccnt />
      </CHeaderNav>
    </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
  methods: {
    changeLanguage(lang) {
      Vue.$cookies.set('locale',lang);
      this.$i18n.locale = lang;
      location.reload();
    },
    getLocale() {
      let locale = Vue.$cookies.get('locale');
      if(!locale) {
        locale = "en";
        Vue.$cookies.set('locale',locale);
      }
      this.$i18n.locale = locale;
      return locale.toString().toUpperCase();
    }
  }
}
</script>
  