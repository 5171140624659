<template>
  <div class="sandbox-mode-banner">
    <div class="sandbox-ribbon"></div>
    <div class="sandbox-mode-text-div">
      <strong class="sandbox-mode-text">
        {{ $t("BANNER.MESSAGE") }}
      </strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheBanner",
};
</script>
<style scoped>
.sandbox-mode-banner {
  color: #2e2e2e;
  position: sticky;
  top: 58px;
  width: 100%;
  z-index: 1;
  text-align: center;
}

.sandbox-ribbon {
  height: 6px;
  background-color: #edb707;
}
.sandbox-mode-text-div {
  max-width: max-content;
  padding: 0 1rem;
  background-color: #edb707;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>