<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    :class="
      $store.getters.getIsSandboxActive ? 'dark_sidebar' : 'light_sidebar'
    "
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 550 148"
        style="padding-right: 100px"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction flat :content-to-render="this.$options.nav" />
    <div
      id="toggle"
      :style="[
        minimize === true
          ? { 'margin-bottom': '100%', 'margin-left': '0.8rem' }
          : { 'margin-bottom': '0%' },
      ]"
    >
      <span id="popover-activate">
        <CSwitch
          v-if="!minimize"
          key="key"
          size="sm"
          color="danger"
          shape="pill"
          @update:checked="showBanner()"
          :checked="$store.getters.getIsSandboxActive"
          :disabled="!$store.getters.getIsMerchantActivated || blockSandboxSwitch"
        />
      </span>
      <b-popover
        v-if="!$store.getters.getIsMerchantActivated"
        custom-class="popover-activate-custom"
        target="popover-activate"
        triggers="hover"
        placement="top"
      >
        <div style="width: 11rem">
          <p class="tooltip-header">Activate to view Live Mode</p>
          <span class="tooltip-data">To view Live Mode, you need to activate your account</span>
        </div>
      </b-popover>
      <strong v-if="!minimize">{{ $t("SIDEBAR.SANDBOX_MODE") }}</strong>
      <div
        v-if="!$store.getters.getIsMerchantActivated"
        class="information-tag"
        id="popover-information"
      >
        <span>!</span>
      </div>
      <b-popover
        v-if="!$store.getters.getIsMerchantActivated"
        target="popover-information"
        triggers="hover"
        placement="bottom"
        custom-class="popover-information"
      >
        <div class="popover-information-div">
          <p class="tooltip-header">You are in Sandbox Mode</p>
          <span class="tooltip-data">
            Any data and transactions made in here are simulated or not
            real
          </span>
        </div>
      </b-popover>
    </div>

    <CSidebarMinimizer class="d-md-down-none" @click.native="toggleSideBar" />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import * as types from "@/store/mutation-types";
import { sandboxEventEmitter } from "@/../config.js";
import * as name from '../router/routeName.js';

export default {
  name: "TheSidebar",
  nav,
  data() {
    return {
      merchantID: null
    };
  },
  methods: {
    toggleSideBar() {
      this.$store.commit(types.toggleSideBar, !this.minimize);
    },
    showBanner() {
      this.$store.commit(
        types.toggleSandboxMode,
        !this.$store.getters.getIsSandboxActive
      );
      this.$eventHub.$emit(
        sandboxEventEmitter.prefix + this.getCurrentPageName()
      );
    },
    activateNowCTA() {}
  },
  computed: {
    show() {
      return this.$store.getters.getSideBarShow;
    },
    minimize() {
      return this.$store.getters.getSideBarMini;
    },
    blockSandboxSwitch() {
      return this.$route.meta.name === name.BATCH_DISBURSEMENT_INFO;
    }
  }
};
</script>

<style>
#toggle {
  margin-left: 6%;
}
span.c-switch-slider {
  margin-top: 0.4rem;
}
#margin {
  margin-bottom: 20%;
}

.information-tag {
  background-color: #418af8;
  border-radius: 50%;
  height: 1.1rem;
  width: 1.1rem;
  text-align: center;
  display: inline-block;
  margin-left: 0.8rem;
  color: black;
  font-size: 0.8rem;
}
.tooltip-header {
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0;
}
.tooltip-data {
  font-size: 10px;
}

.popover-information {
  background-color: #818386;
  margin-left: -2rem;
  margin-bottom: 0.5rem;
}

.popover-information-div {
  width: 10rem;
  color: white;
}

.popover-activate-custom {
  margin-left: 10rem;
  margin-bottom: 0.5rem;
}

#activate-now-cta {
  color: #23b3f1;
  cursor: pointer;
}
</style>
