import { httpClient } from './httpClient.js';

const 
    GETSTICKYNOTIFICATION = '/dashboard/notifications';
const 
    getNotification = (params) => httpClient.dashboardService.get(GETSTICKYNOTIFICATION, { "params": params });

export {
    getNotification
};
 