<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://durian.money" target="_blank">Durianpay</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} PT Durianpay Ltd</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Made with <span style="color: #e25555;">&#9829;</span> across world</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
