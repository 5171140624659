<template>
    <CToaster v-show="showNotification" position="top-system-notification">
      <template>
        <CToast key="toast" :show="true">
          {{ msg }}
          <a :href="redirect_text" style="color='blue';">{{ redirect_url }}</a>
          <button
            :v-show="showCloseButton"
            type="button"
            class="ml-2 mb-1 close"
            @click="showNotification = false"
            data-dismiss="toast"
            aria-label="Close"
          >
          <span aria-hidden="true">&times;</span>
          </button>
        </CToast>
      </template>
    </CToaster>
</template>
<script>
import { getNotification } from "@/api/notification.api.js";

export default {
    Name: 'TheNotification',
    data() {
    return {
      msg: "",
      redirect_text: "",
      showCloseButton: false,
      redirect_url: "",
      showNotification: false
    };
  },
  methods: {
    getAllNotification() {
      const params = new URLSearchParams();
      params.append("locale", process.env.VUE_APP_I18N_LOCALE);
      getNotification(params)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false && response.data.data.message != "") {
            this.msg = response.data.data.message;
            this.redirect_text = response.data.data.redirect_text;
            this.showCloseButton = response.data.data.show_close_button;
            this.redirect_url = response.data.data.redirect_url;
            this.showNotification = true;
          } else {
            this.showNotification = false;
          }
        })
        .catch(e => {
          if (e.response.status != 404) {
              this.handleAPIError(e)
          }
        });
    }
  },
  created() {
    this.getAllNotification();
  }
};
</script>
<style scoped>
</style>
