<template>
  <div>
    <div class="c-app">
      <TheSidebar />
      <CWrapper>
        <TheHeader />
        <TheNotification />
        <TheBanner v-show="toggle" />
        <div class="c-body">
          <main class="c-main pt-3">
            <CContainer fluid>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </CContainer>
          </main>
        </div>

        <TheFooter />
      </CWrapper>
      <!-- </CRow> -->
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import TheNotification from "./TheNotification";
import TheBanner from "./TheBanner";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheNotification,
    TheBanner,
  },
  computed: {
    toggle() {
      return this.$store.getters.getIsSandboxActive;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
