import { i18n } from './../main.js'


export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.HOME'),
        to: '/home',
        icon: 'cil-home',
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.ORDERS'),
        to: '/orders',
        icon: 'cil-list'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.PAYMENTS'),
        to: '/payments',
        icon: 'cil-money'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.SETTLEMENTS'),
        to: '/settlements',
        icon: 'cil-transfer'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.PAYMENT_LINK'),
        to: '/payment_links',
        icon: 'cil-list'
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: i18n.t('SIDEBAR.CUSTOMERS'),
      //   to: '/customers',
      //   icon: 'cil-people'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: i18n.t('SIDEBAR.USERS'),
      //   to: '/users',
      //   icon: 'cil-user'
      // },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.PROMOS'),
        to: '/promos',
        icon: 'cil-badge'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.DISBURSEMENTS'),
        to: '/disbursements',
        icon: 'cil-badge'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.SETTINGS'),
        to: '/settings',
        icon: 'cil-settings'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.ANALYTICS'),
        to: '/analytics',
        icon: 'cil-chart'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.REFUNDS'),
        to: '/refunds',
        icon: 'cil-money'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('SIDEBAR.USER_MANAGEMENT'),
        to: '/user',
        icon: 'cil-user'
      },
    ]
  }
]
